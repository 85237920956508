<template>
    <div :class="data.Class" :style="data.Style">
        <b-button :class="item.Class" :type="item.Type" v-for="(item, n) in data.Data" @click="mainAction(item.OnClick)" :key="n">{{ item.Name }}</b-button>
    </div>
</template>
<script>
export default {
    props: {
        data: Object
    }
}
</script>
